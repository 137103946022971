@import '../../../../styles/variables.scss';

.Wrapper{
    // border-bottom: 1px solid black;
    color: $primaryBackground;
    // background-color: $primaryBackground;
    padding-top: 10px;
    padding-left: 10px;
    padding-right: 10px;
    height: 100px;
    display: flex;
    justify-content: space-between;
    
    .Logo{
        display: flex;
        
        .Image{
            margin-right: 10px;
            height: 100%;
        }
        
        .Title{
            font-size: 40px;
            display: flex;
            align-items: center;
            
            .LogOutButton{
                width: 50px;
                height: 50px;
                margin-left: 10px;
            }
        }
    }

    .Parameters{
        display: flex;
        // align-items: center;

        .PageTitle{
            font-size: 30px;
            height: 100%;
            display: flex;
            align-items: flex-end;
        }
    }
}
.Parameter{
    font-size: 22px;
    margin: 0px;
}

.Column{
    margin-left: 20px;
    width: 200px;
    display: flex;
    flex-direction: column;
}

.AllDevices {
    font-size: 15px;
}