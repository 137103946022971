@font-face {
    font-family: 'AYFM';
    src: url(../../../fonts/HEB.ttf);
    font-style:normal;
    font-display: swap;
}

.Receipt {
    font-family: 'AYFM';
    // font-weight: 1000;
    // font-style: italic;
}

.Wrapper {
    border: 1px solid black;
    // height: 1056px;
	width: 816px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.Header {
    height: 70px; 
    width: 100%; 
    background-color: #FF9100; 
    display: flex;
    font-style: italic;
    justify-content: space-between;
    align-items: center;
}

.Logo {
    width: 55px;
    height: 60px;
    margin-right: 10px;
}

.LogoTitle {
    margin: 0px;
    display: flex;
    align-items: center;
    font-style: normal;
}

.Main {
    padding: 0px 10px 0px 30px;
}

.Table1 {
    border-collapse: collapse; 
    width: 700px; 
    margin-top: 25px; 
    // font-weight: 700; 
    text-decoration-line: underline;
}

.Table1Head {
    background-color: #FF9100; 
    height: 35px;
}

.TableRow {
    height: 35px;
    text-align: center;
}

.TableCell {
    border: 1px solid black; 
    margin: 0px;
}

.SubTable {
    width: 150px; 
    border: 1px solid black; 
    border-top: none;  
    text-align: center;
}
.Table2 {
    border-collapse: collapse; 
    width: 700px; 
    margin-top: 5px; 
    font-weight: 700; 
    text-decoration-line: underline;
}

.Table2Head {
    background-color: #FF9100; 
    height: 60px;
}

.TableRow {
    height: 35px;
    text-align: center;
}

.TableCell {
    border: 1px solid black; 
    margin: 0px;
}

.SubTable {
    width: 150px; 
    border: 1px solid black; 
    border-top: none;  
    text-align: center;
}

.Confirm {
    /* width: 55px; */
    height: 150px;
    padding: 0px 10px 20px 20px;
}

.Footer{
    margin-top: 70px,
}